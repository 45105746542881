import { CloseOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Card, Image, Space, Typography, Upload } from 'antd';
import { useState } from 'react';
import styles from '../../../../static/css/components/file-uploader.module.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { getBackendMediaUrl } from '../../../helpers/media-image-convert';
import PDFViewer from '../../retina/pdf-viewer';
import { setStepTwoComplete } from '../../../reducers/smart-upload-slice';

type PreviewType = {
    url: string,
    type: string,
}

const FileUploader = () => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const csrfToken = useCare1AppSelector((store) => store.user.csrfToken);

    const [previewFile, setPreviewFile] = useState<PreviewType | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const dispatch = useCare1AppDispatch();

    const handlePreview = async (file: UploadFile) => {

        if (file.type === 'application/pdf') {
            setPreviewFile({ url: file.url ?? '', type: 'pdf' });
        } else {
            setPreviewFile({ url: file.url ?? '', type: 'image' });
        }

        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = ({
        fileList: newFileList,
    }) => {
        const updatedFileList = newFileList.map((file) => {
            if (
                file.status === 'done' &&
                file.response &&
                file.response?.thumbUrl
            ) {
                return {
                    ...file,
                    thumbUrl: getBackendMediaUrl() + file.response?.thumbUrl,
                    url: getBackendMediaUrl() + file.response?.url,
                };
            } else {
                return file;
            }
        });

        setFileList(updatedFileList);
        dispatch(setStepTwoComplete(newFileList.length > 0));
    };

    const emptyContent = (
        <div className={styles.dropzoneEmpty}>
            <div className={styles.dropzoneEmptyImage}></div>
            <div className={styles.dropzoneEmptyText}>
                Drag & Drop to upload
            </div>
        </div>
    );

    return (
        <>
            <Upload.Dragger
                action={`${process.env.REACT_APP_BACKENDURL}/data/smart_upload/file_upload/`}
                headers={{ 'X-CSRFToken': csrfToken }}
                data={{ exam: 116313 }}
                withCredentials
                listType='text'
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                openFileDialogOnClick={false}
                itemRender={(orignalNode, file, fileList, actions) => {
                    const url = file.thumbUrl
                        ? file.thumbUrl
                        : file.url
                        ? file.url
                        : '';

                    return (
                        <Card
                            className={styles.imageCard}
                            hoverable
                            onClick={() => handlePreview(file)}
                        >
                            <div className={styles.cardCover}>
                                <img alt={file.fileName} src={url} />
                            </div>
                            <div className={styles.cardTitle}>
                                <Typography.Text ellipsis>
                                    {file.name.split('.')[0]}
                                </Typography.Text>
                            </div>
                            <CloseOutlined
                                className={styles.closeIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    actions.remove();
                                }}
                            />
                        </Card>
                    );
                }}
                className={`${styles.fileUploader} ${styles.uploadWrapper}`}
            > {fileList.length > 0 ? <></> : emptyContent}</Upload.Dragger>

            {previewFile && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                            !visible && setPreviewFile(null),
                        imageRender: (originalNode) => {
                            if (previewFile.type === 'pdf') {
                                return (
                                    <PDFViewer
                                        file={previewFile.url}
                                        onPDFLoadSuccess={() => {}}
                                        onPDFLoadError={() => {}}
                                        onClose={() => {}}
                                    />
                                );
                            } else {
                                return originalNode;
                            }
                        },
                        toolbarRender: (
                            _,
                            {
                                transform: { scale },
                                actions: { onZoomOut, onZoomIn },
                            }
                        ) => {
                            if (previewFile.type === 'pdf') {
                                return null;
                            } else {
                                return (
                                    <Space
                                        size={12}
                                        className={styles.toolbarWrapper}
                                    >
                                        <ZoomOutOutlined
                                            disabled={scale === 1}
                                            onClick={onZoomOut}
                                        />
                                        <ZoomInOutlined
                                            disabled={scale === 50}
                                            onClick={onZoomIn}
                                        />
                                    </Space>
                                );
                            }
                        },
                    }}
                    src={previewFile.url}
                />
            )}
        </>
    );
};

export default FileUploader;
