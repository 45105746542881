import { Button, Modal, Space, Tooltip } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { editExamRequest, saveExamData, setExamDataValue } from '../../../reducers/patient-exam-slice';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { useFormValidation } from '../../../context/useFormValidationContext';
import { INFO_MESSAGE_TITLE } from '../../../constants';
import { useLazySendOutboundReferralEmailOrFaxQuery } from '../../../services/exam-api';
import dayjs from 'dayjs'
import { isOmdc } from '../../../reducers/doctor-slice';

const ReferralLetterButtons = () => {
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const odReferralLetter = useCare1AppSelector(store => store.examData.od_referral_letter);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const examDate = useCare1AppSelector(store => store.examData.exam_date);
    const isOutboundReferral = useCare1AppSelector(store => store.examData.is_outbound_referral);
    const odRequestedOmd = useCare1AppSelector(store => store.examData.od_requested_omd);
    const referralOmd = useCare1AppSelector(store => store.examData.referral_omd);
    const omds = useCare1AppSelector((store) => store.options.omds);


    const examId = useCare1AppSelector((store) => store.examData.id);

    const [sendOutboundReferralEmailOrFax] = useLazySendOutboundReferralEmailOrFaxQuery();

    const isReadOnly = examStatus !== 'not_ready';

    const dispatch = useCare1AppDispatch();

    const { billingRulesForm } = useFormValidation();

    const handleSubmit = () => {
        Promise.all([billingRulesForm && billingRulesForm.validateFields()])
            .then(() => {
                // Only RLU Premium accounts need to have a referral letter.
                if (dayjs().isBefore(dayjs(examDate))) {
                    // Check whether today is before exam date, an exam with a future date cannot be submitted
                    Modal.error({
                        className: 'info-modal',
                        title: `An exam with a future date cannot be submitted. Please submit this exam on or after ${dayjs(
                            examDate
                        ).format('MMM DD YYYY')}`,
                    });
                } else {
                    dispatch(setExamDataValue('exam_status', 'od_review'));

                    if (isOutboundReferral) {
                        dispatch(setExamDataValue('ipc', 'ipc_only'));
                        if (isOmdc(omds, odRequestedOmd)) {
                            dispatch(setExamDataValue('omdc', odRequestedOmd));
                        }

                        dispatch(saveExamData())
                            .then(() => {
                                Modal.info({
                                    className: 'info-modal',
                                    title: INFO_MESSAGE_TITLE,
                                    content: 'Your outbound referral request has been submitted.',
                                });
                                return true;
                            })
                            .then(() => {
                                dispatch(editExamRequest(examId!, () => {}));
                                return true;
                            })
                            .then(() => {
                                return sendOutboundReferralEmailOrFax(examId!).unwrap();
                            })
                            .then((res) => {
                                if (res?.error) {
                                    throw Error(`Error sending outbount referral email or fax, ${res?.error}`);
                                }
                            })
                            .catch((error) => {
                                Modal.error({
                                    className: 'info-modal',
                                    title: String(error),
                                });
                            });
                    } else {
                        dispatch(saveExamData());
                    }
                }
            })
            .catch(() => {
                Modal.error({
                    className: 'info-modal',
                    title:
                        'Exam cannot be submitted until all required fields ' +
                        'are completed. Please fill out required fields.',
                });
            });
    };

    // Takes a boolean value that if true, the page should close after save
    const handleSave = () => {
        dispatch(saveExamData());
    };

    return (
        <Space>
            <Button
                onClick={handleSave}
                type='primary'
                icon={<SaveOutlined />}
                size='large'
                disabled={isReadOnly}
                style={{ width: '8.25rem' }}
            >
                Save
            </Button>

            <Tooltip title='Referral Letter is required'>
                <Button
                    onClick={handleSubmit}
                    type='primary'
                    icon={<SaveOutlined />}
                    size='large'
                    disabled={
                        isReadOnly || (rluExamPremium && !odReferralLetter) || (isOutboundReferral && !referralOmd)
                    }
                    style={{ width: '10rem' }}
                >
                    Submit to Care1
                </Button>
            </Tooltip>
        </Space>
    );
};

export default ReferralLetterButtons;
