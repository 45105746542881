import { useEffect, useCallback } from 'react';
import { useCare1AppDispatch, useCare1AppSelector } from '../apps/care1-hooks';
import { Layout, Modal, Spin, Tabs } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import * as Constants from '../constants';

// Components
import { FormValidationProvider } from '../context/useFormValidationContext';
import ExamDateSelectModal from '../components/retina/exam-date-select-modal';
import ExamTabs from '../components/exam-tabs';
import HelpDeskModal from '../components/retina/help-desk-modal';
import NavigationControlBar from '../components/navigation-control-bar';
import NavigationTopBar from '../components/navigation-top-bar';
import PatientDetailsModal from '../components/retina/patient-details-modal';
import PatientEditModal from '../components/retina/patient-edit-modal';
import PatientExamPreviousVisits from '../components/patient-exam-previous-visits';
import PlaquenilModal from '../components/integrated/plaquenil-modal';
import AdminAIInsights from '../components/admin/admin-ai-insights';

// Helpers
import '../../static/css/pages/ic-patient-exam-page.scss';

// Actions
import PatientInsuranceModal from '../components/integrated/us/patient-insurance-modal';
import { editExamRequest } from '../reducers/patient-exam-slice';
import ICLegacyExamPage from './ic-legacy-exam-page';
import PatientExamReferralLetterPage from './referral-letter-patient-exam-page';
import StandardReferralLetterPatientExamPage from './standard-referral-letter-patient-exam-page';
import GlaucomaWorksheet from '../components/glaucoma-worksheet';

const { Header, Content } = Layout;

const ICPatientExamPage = () => {

    const examOperating = useCare1AppSelector(store => store.examData.operating);
    const patientDetailsOperating = useCare1AppSelector(store => store.patientDetails.operating);
    const showDetails = useCare1AppSelector(store => store.patientDetails.visible);
    const isReferralLetterUploadPEI = useCare1AppSelector(store => store.examData.is_referral_letter_upload_pei);
    const odUsesRluPei = useCare1AppSelector(store => store.examData.od_uses_rlu_pei);
    const rluExamPremium = useCare1AppSelector(store => store.examData.rlu_exam_is_premium);
    const examStatus = useCare1AppSelector(store => store.examData.exam_status);
    const hideExamForOD = useCare1AppSelector(store => store.examData.hide_exam_for_od);

    const displayRluPei = isReferralLetterUploadPEI || odUsesRluPei;

    const rightMlLargeHemePigmentOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_large_heme_pigment_overlay_photo);
    const leftMlLargeHemePigmentOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_large_heme_pigment_overlay_photo);

    const rightMlMicroAneurysmsOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_micro_aneurysms_overlay_photo);
    const leftMlMicroAneurysmsOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_micro_aneurysms_overlay_photo);

    const rightMlHardExudatesOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.right_ml_hard_exudates_overlay_photo);
    const leftMlHardExudatesOverlayPhoto = useCare1AppSelector(store => store.examData.photoUrls.left_ml_hard_exudates_overlay_photo);

    // Redux and React Router Hooks
    const dispatch = useCare1AppDispatch();
    const params = useParams();
    const history = useNavigate();

    // send edit exam request when exam page first renders
    const editExam = useCallback(() => {
        const exam_id = Number(params[Constants.LATEST_EXAM_ID_FIELD]) || null;
        if (exam_id !== null) {
            dispatch(editExamRequest(exam_id, history));
        }
        if (hideExamForOD) {
            Modal.info({
                className: 'info-modal',
                title: Constants.UNAUTHORIZED_MODAL_TITLE,
                content: Constants.UNAUTHORIZED_MODAL_CONTENT,
                onOk: () => {
                   history(Constants.ROOT_URL)
                },
            });
        }
    }, [params, history, dispatch, hideExamForOD])

    useEffect(() => {
        editExam();
    }, [editExam])

    const getTabOrginal = () => {
        return (examOperating) ? <div /> :
            displayRluPei ?
                (rluExamPremium ?
                    <PatientExamReferralLetterPage /> :
                    <StandardReferralLetterPatientExamPage />) :
                <ICLegacyExamPage />
    }

    const getTabAIInsights = () => {

        return <>
            <AdminAIInsights />
        </>;
    }

    const getTabGlaucomaWorksheet = () => {

        return <>
            <GlaucomaWorksheet />
        </>;
    }


    const needAiInsightTab = () => {
        if (rightMlLargeHemePigmentOverlayPhoto !== null && rightMlLargeHemePigmentOverlayPhoto !== '')
            return true;
        if (leftMlLargeHemePigmentOverlayPhoto !== null && leftMlLargeHemePigmentOverlayPhoto !== '')
            return true;
        if (rightMlMicroAneurysmsOverlayPhoto !== null && rightMlMicroAneurysmsOverlayPhoto !== '')
            return true;
        if (leftMlMicroAneurysmsOverlayPhoto !== null && leftMlMicroAneurysmsOverlayPhoto !== '')
            return true;
        if (rightMlHardExudatesOverlayPhoto !== null && rightMlHardExudatesOverlayPhoto !== '')
            return true;
        if (leftMlHardExudatesOverlayPhoto !== null && leftMlHardExudatesOverlayPhoto !== '')
            return true;    

        return false;
    }

    const tabs = [{
        label: 'PEI', key: 'item-pei',
        children: getTabOrginal()
    }];

    tabs.push({
        label: 'Retina Worksheet', key: 'item-ai-insights',
        children: getTabAIInsights()
    });

    if ((isReferralLetterUploadPEI && examStatus !== 'not_ready') || (examStatus !== 'od_review' && examStatus !== 'not_ready'))
        tabs.push({
            label: 'Glaucoma Worksheet', key: 'item-glaucoma-worksheet',
            children: getTabGlaucomaWorksheet(),
        });

    return (
        <Layout>
            {showDetails && <PatientDetailsModal />}
            <Spin
                className="loading-spinner"
                spinning={examOperating || patientDetailsOperating}
                size="large"
                tip={Constants.OPERATING_TEXT}
            >
                <Header>
                    <NavigationTopBar className="navigation-top-bar" />
                    <NavigationControlBar
                        className="ic-pei-navigation-control-bar"
                        breadcrumbItems={[{ key: 1, component: <PatientExamPreviousVisits /> }]}
                    />
                </Header>
                <Content>
                    <PatientEditModal />
                    <HelpDeskModal />
                    <PlaquenilModal />
                    <ExamDateSelectModal />
                    <ExamTabs />
                    <PatientInsuranceModal />
                    <FormValidationProvider>
                        <div className={`ic-patient-exam-page ${displayRluPei ? rluExamPremium ? "referral-letter-pei" : "standard-referral-letter-pei" : ""}`}>
                            <Tabs
                                defaultActiveKey={'item-pei'}
                                items={tabs}
                            />
                        </div>
                    </FormValidationProvider>
                </Content>
            </Spin>
        </Layout>
    );
}

export default ICPatientExamPage;