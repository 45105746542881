import { Typography } from "antd";
import { useCare1AppDispatch, useCare1AppSelector } from "../../../apps/care1-hooks";
import ComponentTextareaCard from "../../component-textarea-card";
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';



const SmartUploadOdNotes = () => {

    const dispatch = useCare1AppDispatch();

    return (
        <ComponentTextareaCard
            title={
                <>
                    <Typography.Text className={styles.odNotesHeading}>OD Notes(Optional)</Typography.Text>
                </>}
            testId='notes-to-moa'
            value={''}
            handleChange={() => {}}
            placeholder="Add Additional Notes"
        />
    );
}

export default SmartUploadOdNotes;