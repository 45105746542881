import { useState, useEffect, ReactNode } from 'react';
import { useCare1AppDispatch } from './apps/care1-hooks';
import { Helmet } from "react-helmet";

import { logout } from './reducers/user-slice';

type ComponentProps = {
    children: ReactNode;
}

const CacheBuster = ({ children }: ComponentProps) => {
    const dispatch = useCare1AppDispatch();

    const appVersion = 'e764a08a-2a89-4814-b68b-19ba54cd13a4';
    console.log(`appVersion: ${appVersion}`);

    const [loading, setLoading] = useState(true);
    const [isLatestVersion, setIsLatestVersion] = useState(false);

    const refreshCacheAndReload = () => {
        dispatch(logout());
        console.log('Clearing cache...')
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }

        // delete browser cache and hard reload
        console.log('Hard reloading...')
        window.location.reload();

        console.log('Redirecting to login page...')
        window.location.href = "/login";
    }

    const doFetch = () => {
        fetch('/meta.json?t=' + (new Date().getTime()))
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = appVersion;

                const shouldForceRefresh = (latestVersion !== currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    setLoading(false);
                    setIsLatestVersion(false);
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    setLoading(false);
                    setIsLatestVersion(true);
                }
            });
    }

    useEffect(() => {
        doFetch();
    }, []);

    useEffect(() => {
        if (!loading && !isLatestVersion) {
            // Call refreshCacheAndReload when component needs to be refreshed
            refreshCacheAndReload();
        }
        // eslint-disable-next-line
    }, [loading, isLatestVersion]);

    return <>
        <Helmet>
            <meta name="care1:version" content={appVersion} />
        </Helmet>
        {children}
    </>;
}

export default CacheBuster;

