import { createAsyncThunk, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { logout } from './user-slice';
import { PATIENT_SEARCH_VALUE_FIELD } from '../constants';
import { buildADMINPatientListFilter, buildODPatientListFilter, buildOMDCPatientListFilter,
    buildOMDRPatientListFilter, convertADMINPatientListToTable, convertODPatientListToTable,
    convertOMDCPatientListToTable, convertOMDRPatientListToTable
    } from '../helpers/patient-list-convert';
import { getCsrfToken } from '../helpers/utilities';
import { apiRequest } from '../services/api-request';
import { clearPatientDetailsData } from './patient-details-slice';
import { clearPatientExamRoomsData } from './patient-exam-rooms-slice';
import { clearExamData } from './patient-exam-slice';
import { RootState } from "../stores/retina-enabled-store";

type ValueOf<T> = T[keyof T];
type ValueOfPatientList = ValueOf<IPatientList>;

// Interfaces for table list items for each platform
export interface IODPatientListItem {
    age: number;
    chief_complaint: string;
    dob: string;
    first_name: string;
    is_selected: boolean;
    key: number;
    last_name: string;
    last_reviewed: string;
    last_visit: string;
    latest_exam_id: number;
    latest_exam_status: string;
    od: string;
    visits: number;
    referral_status: string;
    submission_date: string;
}

export interface IODPatientListItemResponse extends IODPatientListItem {
    id: number;
}
export interface IOMDRPatientListItem {
    alerts: string;
    b_disc: {
        current: string,
        field_name: string,
        mode_disc: string,
        prev: string,
        prev2: string,
    }[];
    b_field: {
        current: string,
        field_name: string,
        mode_disc: string,
        prev: string,
        prev2: string,
    }[];
    b_iop: {
        current_iop: string,
        iop_mean: number,
        prev2_iop: string,
        prev_iop: string,
        type: string,
    }[];
    chief_complaint: string;
    complexity: string;
    dob: string;
    filter_type: string;
    first_name: string;
    flag: boolean;
    glc_class: {
        od_iop_aim: null | number,
        os_iop: string,
        os_iop_aim: null | number,
        od_iop: string,
        glc_status: string,
    };
    iop: {
        os_iop: string,
        od_alert: boolean,
        od_iop: string,
        os_alert: boolean
    };
    isOdIntegrated: boolean;
    isOdOrientating: boolean;
    isOdSpecialAttention: boolean;
    key: number;
    last_name: string;
    latest_visit: string;
    latest_visit_id: number;
    od: string;
    omdc: null | number;
    pr_alerts: string[];
    province: string;
    prvwr: string;
    special_notes: string;
    status: string;
    visits: number;
    isReferralLetterUploadPei: boolean;
}

export interface IOMDRPatientListItemResponse extends IOMDRPatientListItem {
    id: number;
    age: number;
}
export interface IOMDCPatientListItem {
    chief_complaint: string;
    dob: string;
    exam_date: string;
    first_name: string;
    key: number;
    last_name: string;
    latest_exam_id: number;
    od: string;
    omdc_status: string;
    visits: number;
}

export interface IOMDCPatientListItemResponse extends IOMDCPatientListItem {
    id: number;
    age: number;
}
export interface IAdminPatientListItem {
    age: number;
    alerts: string;
    dob: string;
    egp_status: string;
    exam_id: number;
    fax: string;
    fax2: string;
    filter_type: string;
    first_name: string;
    flag: boolean;
    gp_status: string;
    igp: string;
    igp_fax: string;
    igp_status: string;
    isOdOrientating: boolean;
    isOdSpecialAttention: boolean;
    isUsOnly: boolean;
    is_inactive: boolean;
    key: number;
    last_name: string;
    latest_visit: string;
    od: string;
    od_group: string;
    od_integrated: boolean;
    od_retina_only: boolean;
    od_essentials_only: boolean;
    omdc: string;
    omdc_status: string;
    omdr: string;
    pr_alerts: string[];
    pre_reviewer: string;
    province: string;
    referral_type: string;
    special_notes: string;
    status: string;
    test_patient: boolean;
    visits: number;
    visit_number: number;
    patient_deactivated: boolean;
    red_flag: boolean;
    submission_date: string;
    od_requested_omd: string;
}

export type IAdminPatientListItemResponse = Omit<IAdminPatientListItem, 'isUsOnly'> & {
   is_us_only: boolean;
   red_flag: boolean;
}

export interface IGlaucomaProgramPatientListItem {
    chief_complaint: string;
    first_name: string;
    key: number;
    exam_id: number;
    last_name: string;
    last_visit: string;
    next_suggested_visit: string;
    testing_needed: string;
    testing_frequency: string;
    booking_status: string;
    comments: string;
    iop: {
        os_iop: string,
        od_iop: string,
    };
    cd: {
        os_cd: string,
        od_cd: string,
    };
    vf: {
        os_vf: string,
        od_vf: string,
    }
}

export interface IGlaucomaProgramPatientListItemResponse extends IGlaucomaProgramPatientListItem {
    id: number;
}

// Slice main interface and state
export interface IPatientList {
    operating: boolean;
    odTableList: IODPatientListItem[];
    omdrTableList: IOMDRPatientListItem[];
    omdcTableList: IOMDCPatientListItem[];
    adminTableList: IAdminPatientListItem[];
    examPeriod: string;
    examStatus: string;
    patientGrouping: string;
    searchQuery: string;
    omdcStatus: string;
    omdrStatus: string;
    omdrPeriodFilter: string | null;
    adminOdProgramFilter: string | null;
    adminPeriodFilter: string | null;
    adminSearchOMDFilter: string | null;
    adminSearchODFilter: number | null;
    adminSearchGPFilter: string | null;
    adminSearchIGPFilter: string | null;
    adminUnflaggedFilter: string | null;
    adminFlaggedFilter: string | null;
    adminMiscFilter: string | null;
    adminAuditRecordFilter: string | null;
    adminAuditRecordStartDate: string | null;
    adminAuditRecordEndDate: string | null;
    adminBulkOperation: string | null;
    adminBulkIGP: number | null;
    adminBulkOMDC: number | null;
    adminBulkOMDR: number | null;
    inactivePatientChecked: boolean;
    inactiveExamChecked: boolean;
    omdrStartDate: string | null;
    omdrEndDate: string | null;
    testPatientChecked: boolean;
}
const initialState : IPatientList = {
    operating: false,
    odTableList: [],
    omdrTableList: [],
    omdcTableList: [],
    adminTableList: [],
    examPeriod: '12_months',
    examStatus: 'not_ready_od_review',
    patientGrouping: 'doctor',
    searchQuery: '',
    omdcStatus: 'ready',
    omdrStatus: 'ready',
    omdrPeriodFilter: null,
    adminOdProgramFilter: null,
    adminPeriodFilter: null,
    adminSearchOMDFilter: null,
    adminSearchODFilter: null,
    adminSearchGPFilter: null,
    adminSearchIGPFilter: null,
    adminUnflaggedFilter: null,
    adminFlaggedFilter: null,
    adminMiscFilter: null,
    adminAuditRecordFilter: null,
    adminAuditRecordStartDate: null,
    adminAuditRecordEndDate: null,
    adminBulkOperation: null,
    adminBulkIGP: null,
    adminBulkOMDC: null,
    adminBulkOMDR: null,
    inactivePatientChecked: true,
    inactiveExamChecked: true,
    testPatientChecked: true,
    omdrStartDate: null,
    omdrEndDate: null,
};

// Response data interfaces
interface IODPatientListResponseData {
    patients_data_list: IODPatientListItemResponse[];
}
interface IOMDRPatientListResponseData {
    patients_data_list: IOMDRPatientListItemResponse[];
}
interface IOMDCPatientListResponseData {
    patients_data_list: IOMDCPatientListItemResponse[];
}
interface IAdminPatientListResponseData {
    patients_data_list: IAdminPatientListItemResponse[];
}
export interface IGlaucomaProgramPatientListResponseData {
    patients_data_list: IGlaucomaProgramPatientListItemResponse[];
}

// GET: getReferredPatientListRequest
export const getODPatientListRequest = createAsyncThunk(
    'patientList/getODPatientList',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken, isOD }, patientList } = getState() as RootState;
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        // Clear actions called on every patient list request
        dispatch(onRequestClearState())

        // Get the formatted filter for the request header
        const filter = buildODPatientListFilter(patientList, isOD);

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/${filter}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

// GET: getOMDRPatientListRequest
export const getOMDRPatientListRequest = createAsyncThunk(
    'patientList/getOMDRPatientList',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, patientList } = getState() as RootState;
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        // Clear actions called on every patient list request
        dispatch(onRequestClearState())

        // Get the formatted filter for the request header
        const filter = buildOMDRPatientListFilter(patientList);

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/${filter}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

// GET: getOMDCPatientListRequest
export const getOMDCPatientListRequest = createAsyncThunk(
    'patientList/getOMDCPatientList',
    async (_, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, patientList } = getState() as RootState;
        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        // Clear actions called on every patient list request
        dispatch(onRequestClearState())

        // Get the formatted filter for the request header
        const filter = buildOMDCPatientListFilter(patientList);

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/${filter}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

// GET: getAdminPatientListRequest
export const getAdminPatientListRequest = createAsyncThunk(
    'patientList/getAdminPatientList',
    async (_:void, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken }, patientList } = getState() as RootState;

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        // Clear actions called on every patient list request
        dispatch(onRequestClearState())

        // Get the formatted filter for the request header
        const filter = buildADMINPatientListFilter(patientList);

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/${filter}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

// GET: getSearchPatientListRequest
export const getOMDRSearchPatientListRequest = createAsyncThunk(
    'patientList/getSearchOMDRPatientList',
    async (value: string, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as RootState;

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        // Get the formatted filter for the request header
        const filter = `?${PATIENT_SEARCH_VALUE_FIELD}=${value}`;

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/${filter}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

// GET: getOMDCSearchPatientListRequest
export const getOMDCSearchPatientListRequest = createAsyncThunk(
    'patientList/getSearchOMDCPatientList',
    async (value: string, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as RootState;

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        // Get the formatted filter for the request header
        const filter = `?${PATIENT_SEARCH_VALUE_FIELD}=${value}`;

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/${filter}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

// GET: getAdminSearchPatientListRequest
export const getAdminSearchPatientListRequest = createAsyncThunk(
    'patientList/getSearchAdminPatientList',
    async (value: string, {dispatch, getState, rejectWithValue}) => {
        const { user: { csrfToken } } = getState() as RootState;

        // Logout if tokens don't match.
        if (csrfToken !== getCsrfToken()) {
            dispatch(logout());
        }

        // Get the formatted filter for the request header
        const filter = `?${PATIENT_SEARCH_VALUE_FIELD}=${value}`;

        const URL = `${process.env.REACT_APP_BACKENDURL}/data/patients/${filter}`;

        try {
            const response = await apiRequest.get(URL, csrfToken);
            return response.data;
        } catch (error) {
            if (error) {
                return rejectWithValue(error);
            }
        }
    }
)

// Actions to clear data that are called with every request
export const onRequestClearState = () => (dispatch: Dispatch) => {
    dispatch(clearPatientDetailsData());
    dispatch(clearExamData());
    dispatch(clearPatientExamRoomsData());
}

export const patientListSlice = createSlice({
    name: 'patientList',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPatientListFieldData: (state, action: PayloadAction<{key: keyof IPatientList, value: ValueOfPatientList}>) => {
            return {
                ...state,
                [action.payload.key]:action.payload.value,
            }
        },
        clearAllFilters: (state, action: PayloadAction<void>) => {
            return {
                ...state,
                adminPeriodFilter: null,
                adminOdProgramFilter: null,
                adminUnflaggedFilter: null,
                adminFlaggedFilter: null,
                adminMiscFilter: null,
                adminSearchOMDFilter: null,
                adminSearchGPFilter: null,
                adminSearchIGPFilter: null,
                adminSearchODFilter: null,
                adminAuditRecordFilter: null,
                adminAuditRecordStartDate: null,
                adminAuditRecordEndDate: null,
                inactivePatientChecked: false,
                inactiveExamChecked: false,
                testPatientChecked: false,
                // Clear Bulk Operation filters
                adminBulkOperation: null,
                adminBulkIGP: null,
                adminBulkOMDR: null,
                adminBulkOMDC: null,
                // Clear Patient List page
                adminTableList: [],
            }
        },
    },
    extraReducers: (builder) => {
        //getODPatientListRequest
        builder.addCase(getODPatientListRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getODPatientListRequest.fulfilled, (state,
            action: PayloadAction<IODPatientListResponseData>) => {

            const processedList = convertODPatientListToTable(action.payload.patients_data_list);

            return {
                ...state,
                operating: false,
                odTableList: processedList,
            }
        });
        builder.addCase(getODPatientListRequest.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting OD Patient List. ${action.payload}`,
            })
        });

        //getOMDRPatientListRequest
        builder.addCase(getOMDRPatientListRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getOMDRPatientListRequest.fulfilled, (state,
            action: PayloadAction<IOMDRPatientListResponseData>) => {

            const processedList = convertOMDRPatientListToTable(action.payload.patients_data_list);

            return {
                ...state,
                operating: false,
                omdrTableList: processedList,
            }
        });
        builder.addCase(getOMDRPatientListRequest.rejected, (state, action) => {
            state.operating = false

            // ToDo: Uncomment code in CP3-1663
            // Modal.error({
            //     className: 'info-modal',
            //     title: `Errors getting OMDR Patient List. ${action.payload}`,
            // })
        });

        //getOMDCPatientListRequest
        builder.addCase(getOMDCPatientListRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getOMDCPatientListRequest.fulfilled, (state,
            action: PayloadAction<IOMDCPatientListResponseData>) => {

            const processedList = convertOMDCPatientListToTable(action.payload.patients_data_list);

            return {
                ...state,
                operating: false,
                omdcTableList: processedList,
            }
        });
        builder.addCase(getOMDCPatientListRequest.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting OMDC Patient List. ${action.payload}`,
            })
        });

        //getAdminPatientListRequest
        builder.addCase(getAdminPatientListRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getAdminPatientListRequest.fulfilled, (state,
            action: PayloadAction<IAdminPatientListResponseData>) => {

            const processedList = convertADMINPatientListToTable(action.payload.patients_data_list);

            return {
                ...state,
                operating: false,
                adminTableList: processedList,
            }
        });
        builder.addCase(getAdminPatientListRequest.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors getting Admin Patient List. ${action.payload}`,
            })
        });

        //getOMDRSearchPatientListRequest
        builder.addCase(getOMDRSearchPatientListRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getOMDRSearchPatientListRequest.fulfilled, (state,
            action: PayloadAction<IOMDRPatientListResponseData>) => {

            const processedList = convertOMDRPatientListToTable(action.payload.patients_data_list);

            return {
                ...state,
                operating: false,
                omdrTableList: processedList,
            }
        });
        builder.addCase(getOMDRSearchPatientListRequest.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors searching OMDR Patient List. ${action.payload}`,
            })
        });

        //getOMDCSearchPatientListRequest
        builder.addCase(getOMDCSearchPatientListRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getOMDCSearchPatientListRequest.fulfilled, (state,
            action: PayloadAction<IOMDCPatientListResponseData>) => {

            const processedList = convertOMDCPatientListToTable(action.payload.patients_data_list);

            return {
                ...state,
                operating: false,
                omdcTableList: processedList,
            }
        });
        builder.addCase(getOMDCSearchPatientListRequest.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors searching OMDC Patient List. ${action.payload}`,
            })
        });

        //getAdminSearchPatientListRequest
        builder.addCase(getAdminSearchPatientListRequest.pending, (state) => {
            state.operating = true;
        });
        builder.addCase(getAdminSearchPatientListRequest.fulfilled, (state,
            action: PayloadAction<IAdminPatientListResponseData>) => {

            const processedList = convertADMINPatientListToTable(action.payload.patients_data_list);

            return {
                ...state,
                operating: false,
                adminTableList: processedList,
            }
        });
        builder.addCase(getAdminSearchPatientListRequest.rejected, (state, action) => {
            state.operating = false
            Modal.error({
                className: 'info-modal',
                title: `Errors searching Admin Patient List. ${action.payload}`,
            })
        });
    }
});

export const { setPatientListFieldData, clearAllFilters } = patientListSlice.actions;

export default patientListSlice.reducer;