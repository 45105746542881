import { Card, Col, Flex, Row, Typography } from 'antd';
import '../../../../static/css/components/patient-exam-metrics.scss';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';
import MBPatientExamDtcIOP from '../metrics/mb-patient-exam-dtc-iop';
import PatientExamDtcHour from '../metrics/patient-exam-dtc-hour';


const SmartUploadMiniDtc = () => {
    return (
        <Card bordered={false} className={styles.miniDtc} size='small'>
            <Row gutter={[0, 32]}>
                <Col span={24}>
                    <Flex vertical>
                        <Typography.Text className={styles.dtcHourText}>
                            Time of 1st IOP
                        </Typography.Text>
                        <PatientExamDtcHour disabled={false} isSmartUpload />
                    </Flex>
                </Col>

                <Col span={24}>
                    <Flex gap={12}>
                        <Col span={11}>
                            <MBPatientExamDtcIOP
                                side='od'
                                disabled={false}
                                isSmartUpload
                            />
                        </Col>
                        <Col span={11}>
                            <MBPatientExamDtcIOP
                                side='os'
                                disabled={false}
                                isSmartUpload
                            />
                        </Col>
                    </Flex>
                </Col>
            </Row>
        </Card>
    );
};

export default SmartUploadMiniDtc;
