import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, ConfigProvider, Flex, Modal, Row, Steps, Typography } from 'antd';
import { StepProps } from 'antd/lib';
import { useState } from 'react';
import styles from '../../../../static/css/components/smart-upload-modal.module.scss';
import { useCare1AppDispatch, useCare1AppSelector } from '../../../apps/care1-hooks';
import { toggleSmartUploadModal } from '../../../reducers/smart-upload-slice';
import SmartUploadStep1 from './smart-upload-step1';
import SmartUploadStep2 from './smart-upload-step2';
import classnames from 'classnames';

const theme = {
    components: {
        Steps: {
            navArrowColor: 'transparent',
            colorTextDescription: '#FFFFFF',
            colorText: '#FFFFFF',
        },
        Select: {
            optionActiveBg: 'transparent',
            activeBorderColor: '#475766',
            colorBorder: '#475766',
            colorTextPlaceholder: '#BFDFFF',
            selectorBg: '#12161A',
            colorTextQuaternary: '#FFFFFF',
        },
        Typography: {
            colorText: '#FFFFFF',
        },
        Modal: {
            contentBg: '#1F2226',
            colorIcon: '#FFFFFF',
        },
        Card: {
            padding: 0,
        },
        Button: {
            colorBgContainerDisabled: 'transparent',
            colorTextDisabled: '#7F7F7F',
            borderColorDisabled: '#7F7F7F',
            paddingBlock: 8,
            defaultColor: '#D9D9D9',
            defaultBg: 'transparent',
            defaultBorderColor: '#D9D9D9',
            defaultHoverBg: 'transparent',
            defaultHoverBorderColor: '#D9D9D9',
            defaultHoverColor: '#D9D9D9',
            borderRadius: 2,
            onlyIconSize: 8,
            controlHeight: 12,
        },
        InputNumber: {
            addonBg: '#303940',
            colorBorder: '#303940',
            colorBgContainer: '#12161A',
            colorText: '#FFFFFF',
            controlHeightLG: 38,
            borderRadiusLG: 5,
        }
    },
}

interface ExtendedStepProps extends StepProps {
    content: React.ReactNode;
}


const SmartUploadModal = () => {
    const isVisible = useCare1AppSelector(
        (store) => store.smartUpload.smartUploadModalOpen
    );
    const stepTwoComplete = useCare1AppSelector(
        (store) => store.smartUpload.stepTwoComplete
    );
    const dispatch = useCare1AppDispatch();

    const handleModalClose = () => {
        dispatch(toggleSmartUploadModal());
    };

    const [currentStep, setCurrentStep] = useState(0);

    const stepItems: ExtendedStepProps[] = [
        {
            status: 'wait',
            title: (
                <>
                    <Typography.Text className={styles.stepTitle}>
                        Step 1: Letter
                    </Typography.Text>
                    <span>&gt;</span>
                </>
            ),
            icon: <></>,
            content: <SmartUploadStep1 />,
        },
        {
            status: 'wait',
            title: (
                <>
                    <Typography.Text className={styles.stepTitle}>
                        Step 2: Diagnostics
                    </Typography.Text>
                    <span>&gt;</span>
                </>
            ),
            icon: <></>,
            content: <SmartUploadStep2 />,
        },
        {
            status: 'wait',
            title: (
                <Typography.Text className={styles.stepTitle}>
                    Step 3: Confirmation
                </Typography.Text>
            ),
            icon: <></>,
            content: <div>Step3</div>,
        },
    ];
    const classNames = classnames(styles.smartUploadModal,{
        [styles.diagnosticStep]: currentStep === 1,
    })

    return (
        <ConfigProvider theme={theme}>
            <Modal
                className={classNames}
                open={isVisible}
                closable={true}
                closeIcon={<CloseOutlined />}
                footer={null}
                keyboard={true}
                onCancel={handleModalClose}
                width={756}
            >
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Typography.Title
                            level={5}
                            className={styles.modalTitle}
                        >
                            Smart Upload
                        </Typography.Title>
                    </Col>

                    <Col span={24}>
                        <Flex justify='center'>
                            <Col span={18}>
                                <Steps
                                    type='navigation'
                                    className='site-navigation-steps'
                                    current={currentStep}
                                    onChange={(clickedStep) =>
                                        setCurrentStep(clickedStep)
                                    }
                                    items={stepItems}
                                />
                            </Col>
                        </Flex>
                    </Col>
                    {stepItems[currentStep].content}
                    <Col span={24}>
                        <Flex justify='space-between'>
                            <Flex gap={8} align='center'>
                                {currentStep !== 0 && (
                                    <Button
                                        className={`${styles.button} ${styles.backButton}`}
                                        onClick={() =>
                                            setCurrentStep(currentStep - 1)
                                        }
                                    >
                                        Back
                                    </Button>
                                )}
                                {currentStep === 0 && (
                                    <Button
                                        type='primary'
                                        className={styles.button}
                                        onClick={() =>
                                            setCurrentStep(currentStep + 1)
                                        }
                                    >
                                        Next
                                    </Button>
                                )}
                                {currentStep === 1 && (
                                    <>
                                        {stepTwoComplete ? (
                                            <Button
                                                type='primary'
                                                className={styles.button}
                                                onClick={() =>
                                                    setCurrentStep(
                                                        currentStep + 1
                                                    )
                                                }
                                            >
                                                Next
                                            </Button>
                                        ) : (
                                            <Button
                                                type='primary'
                                                className={styles.button}
                                                onClick={() =>
                                                    setCurrentStep(
                                                        currentStep + 1
                                                    )
                                                }
                                            >
                                                Skip
                                            </Button>
                                        )}
                                        <Typography.Text
                                            className={styles.autoSaveText}
                                        >
                                            (All changes are Auto-saved)
                                        </Typography.Text>
                                    </>
                                )}
                            </Flex>
                            <Button
                                type='primary'
                                disabled
                                className={styles.button}
                            >
                                Delete
                            </Button>
                        </Flex>
                    </Col>
                </Row>
            </Modal>
        </ConfigProvider>
    );
};

export default SmartUploadModal;
