import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../stores/retina-enabled-store';

type ValueOf<T> = T[keyof T];
type ValueOfSmartUploadData = ValueOf<ISmartUpload>;
export interface ISmartUpload {
    smartUploadModalOpen: boolean;
    stepTwoComplete: boolean;
    dtc_values: {od?: number | null, os?: number | null }[];

}

const initialState: ISmartUpload = {
    smartUploadModalOpen: false,
    stepTwoComplete: false,
    dtc_values: [],
};

export const setSmartUploadDataValue = (key: keyof ISmartUpload, value: ValueOfSmartUploadData ) =>
    (dispatch: AppDispatch) =>{
    dispatch(setSmartUploadSliceDataValue({key, value}));
}

export const smartUploadSlice = createSlice({
    name: 'smartUploadSlice',
    initialState,
    reducers: {
        toggleSmartUploadModal: (state) => {
            state.smartUploadModalOpen = !state.smartUploadModalOpen;
        },
        setStepTwoComplete: (state, action: PayloadAction<boolean>) => {
            state.stepTwoComplete = action.payload;
        },
        setSmartUploadSliceDataValue: (state, action: PayloadAction<{key: keyof ISmartUpload, value: ValueOfSmartUploadData}>) => {
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        },
    },
});

export const { toggleSmartUploadModal, setStepTwoComplete, setSmartUploadSliceDataValue } = smartUploadSlice.actions;

export default smartUploadSlice.reducer;
